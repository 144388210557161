.o-app {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    min-height: 100%;
}

.o-app:after {
    content: ""; /* Important, sinon l'élément n'est pas généré. */
    display: table;
    clear: both;
}

a {
    color: var(--primary-color) !important;
}
