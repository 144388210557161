.o-artist-box {
    border-radius: .5rem;
    height: 15rem;
    width: 15rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 1rem .5rem .5rem .5rem;
    text-align: center;
    color: white;
    overflow: hidden;
    background-color: var(--base-text-color);
    border: 2px solid var(--base-text-color);
}

.o-artist-box:hover {
    border-color: var(--primary-color);
    cursor: pointer;
}

.o-artist-box-picture {
    margin-bottom: 1rem;
    height: 10rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.o-artist-box-name {
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
}

.o-artist-box-info {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    flex-wrap: nowrap;
    align-items: flex-end;
    font-size: small;
}
