.o-email-validation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
};

.o-email-validation-container {
    color: #ffffff;
    font-size: 1.5em;
    text-align: center;
}

.o-email-validation-logo {
    width: 60%;
    max-width: 15rem;
    min-width: 10rem;
    text-align: center;
    margin: .5rem 0 2rem 0;
}