.o-accountmenu {
    width: 100%;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 3px 3px 3px #c0c0c0;
    overflow: hidden;
    z-index: 1000;
}

.o-accountmenu ul {
    margin: 0;
    padding: 0;
}

.o-accountmenu ul:first-child {
    border-radius: 5px 5px 0 0;
}

.o-accountmenu ul:last-child {
    border-radius: 5px 5px 0 0;
}

.o-accountmenu li {
    cursor: pointer;
    font-weight: bold;
    list-style-type: none;
    padding: .5em;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
}

.o-accountmenu li:hover {
    color: var(--button-c);
    background: var(--button-bg);
}
