.o-dialog-on-air h1 {
    font-size: var(--font-size-l);
}

.o-dialog-on-air h2 {
    font-size: var(--font-size-l);
    text-align: left;
}

.o-dialog-on-air p {
    text-align: left;
}

.o-dialog-on-air .o-input-text {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    text-align: left;
}

.o-dialog-on-air .p-selectbutton {
    text-align: left;
}

.o-dialog-on-air .o-input-text label {
    margin-left: .5rem;
}

.o-dialog-on-air-spinner {
    text-align: center !important;
}

.o-dialog-on-air-warning {
    background-color: var(--warning-bg);
    color: var(--warning-c);
    padding: .3rem;
}
