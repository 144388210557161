.o-new-button {
    align-items: flex-end;
    display: flex;
    margin-bottom: 1rem;
    justify-content: flex-end;
}

.o-stakeholders-page-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: .5rem;
}
