.o-panel {
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    margin-bottom: 2rem;
}

.o-panel-title {
    align-items: center;
    background-color: var(--panel-title-bg);
    border-radius: 1rem 1rem 0 0;
    color: var(--panel-title-c);
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    padding: 1rem 2rem;
    text-transform: uppercase;
}

.o-panel-title-icon {
    cursor: pointer;
}

.o-panel-children {
    background-color: var(--panel-children-bg);
    padding: 1rem 2rem;
}

.o-panel-children-active {
    transition: opacity 0.5s linear;
    transform: translate(0);
    opacity: 1;
}

.o-panel-children-collapsed {
    transition: all 0.5s linear;
    opacity: 0;
    height: 0;
    border: 0;
    padding: 0;
}
