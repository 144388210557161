.o-post-stakeholder-name {
    padding-left: .5rem;
    text-align: left !important;
}

.o-post-stakeholder-total {
    font-weight: bold;
    text-align: right !important;
}

.o-post-stakeholder-rate {
    font-weight: bold;
}