.o-submit-button button {
    background-color: var(--button-bg);
    color: white;
    border: solid 1px var(--button-b);

    border-radius: .2rem;
    cursor: pointer;
    width: 100%;
    font-size: 1rem;
    font-weight: bold;
    padding: .8rem 1.5rem;
    text-transform: uppercase;

    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.o-submit-button button:hover {
    background-color: white;
    color: var(--button-bg);
    border: solid 1px var(--button-b);
}

.o-submit-button button:disabled,
.o-submit-button button[disabled] {
    background: var(--button-disabled-bg);
    border: solid 1px var(--button-disabled-b);
    cursor: not-allowed;
    color: white;
}

.o-submit-button.secondary button {
    background-color: white;
    color: var(--button-bg);
    border: solid 1px var(--button-b);
}

.o-submit-button.secondary button:hover {
    background-color: var(--button-bg);
    color: white;
    border: solid 1px var(--button-b);
}
