.o-account {
    height: 5.2rem;
}

.o-account-avatar {
    height: 5.2rem;
}

.o-account-picture {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 2.6rem;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 5.2rem;
    height: 5.2rem;
    border: solid 2px var(--account-picture-b);
}

.o-account-icon {
    align-items: center;
    bottom: 1.7rem;
    border-radius: .5rem;
    color: #000000;
    cursor: pointer;
    display: flex;
    height: 1.2rem;
    justify-content: center;
    left: calc(50% + 1.5rem);
    position: relative;
    width: 1.2rem;
    background: var(--account-icon-bg);
}

.o-account-icon:hover {
    filter: brightness(0.8);
}

.o-account-menu {
    position: relative;
    bottom: .4rem;
    color: #000000;
    left: calc(50% + 1.5rem);
    transition: all 100ms ease-out;
    width: 0;
}

.o-account-menu.active {
    transition: all 100ms ease-out;
    width: 12rem;
}
