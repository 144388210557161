.o-add-stakeholder {
    height: 15rem;
    text-align: left;
}

.o-add-stakeholder-actual {
    padding: .2rem;
    margin-bottom: 1rem;
}

.o-add-stakeholder-actual.o-max {
    color: var(--button-c);
    background-color: var(--primary-color);
}

.o-add-stakeholder-title {
    padding: .2rem;
    margin-bottom: 1rem;
}

.o-add-stakeholder-item {
    display: flex;
    flex-direction: row;
    margin-bottom: .3rem;
}

.o-add-stakeholder-item-name {
    align-items: center;
    display: flex;
    flex-grow: 1;
    padding: .2rem;
}

.o-add-stakeholder-item-share {
    flex-grow: 0;
}

.o-add-stakeholder-item-pc {
    display: flex;
    align-items: center;
    flex-grow: 0;
    padding-left: .5rem;
}

.o-add-stakeholder-item-action {
    align-items: center;
    display: flex;
    padding-left: .5rem;
}

.o-add-stakeholder-item-action .o-button-icon {
    font-size: .5rem;
    height: 1rem;
    width: 1rem;
}

.o-add-stakeholder-item .o-input-text {
    margin: 0;
}

.o-add-stakeholder-item .o-input-text input {
    padding: .4rem .2rem .2rem .2rem;
    text-align: right;
    width: 4rem;
}

.o-add-stakeholder-share-info {
    padding-top: 1rem;
}

.o-add-stakeholder .o-input-text-completion {
    padding: 0;
    top: 45px;
}

.o-add-stakeholder .o-input-text-completion li {
    padding: .3rem .5rem;
}

.o-add-stakeholder .o-input-text-completion li:hover {
    background-color: var(--button-b);
    color: var(--button-c);
}