.o-input-select {
    color: #000000;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.o-input-select label {
    margin-bottom: .4rem;
}

.o-input-select select {
    border: 2px solid #000000;
    padding: .8rem 1.5rem;
    border-radius: .2rem;
    font-size: 1rem;
    min-width: 12rem;
}
