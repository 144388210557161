.o-recovery {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding-top: 4rem;
    width: 100%;
}

.o-recovery-logo {
    width: 60%;
    max-width: 15rem;
    min-width: 10rem;
    text-align: center;
    margin: .5rem 0 2rem 0;
}

.o-recovery-message {
    font-size: 1.5em;
    padding: 0 1rem;
    text-align: center !important;
}
