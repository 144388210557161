.o-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 2rem;
    overflow: auto;
}

.o-page-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -5rem;
    opacity: .5;
    z-index: 1000 !important;
}

.o-page-children {
    padding-bottom: 2rem;
}
