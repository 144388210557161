.o-page-container {
    width: 100%;
}

.o-page-container-body {
    background-color: var(--page-container-body-bg);
}

.o-page-container-side {
    background-color: var(--side-menu-bg);
}
