.o-artist-chat {
    align-items: center;
    display: flex;
    justify-content: center;
}

.o-artist-chat-container {
    display: flex;
    flex-direction: column;
    height: 50rem;
    width: 30rem;
}

.o-artist-chat-header {
    background: #202020;
    border-radius: .5rem .5rem 0 0;
    color: white;
    padding: 1rem;
}

.o-artist-chat-list {
    justify-content: flex-end;
    background-color: #191a1e;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    padding: 1rem;
}

.o-artist-chat-message {
    background-color: #0e0f11;
    border-radius: 1rem 1rem 1rem 0;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    margin-top: .5rem;
    width: 100%;
}

.o-artist-chat-avatar {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 6rem;
}

.o-artist-chat-picture {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 1.5rem;
    margin: 0 auto;
    width: 3rem;
    height: 3rem;
}

.o-artist-chat-text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: .5rem .5rem .5rem 0;
    width: 100%;
}

.o-artist-chat-text .footer,
.o-artist-chat-text .header {
    color: #8e8e8e;
    font-size: .9rem;
}

.o-artist-chat-text .body {
    margin: .3rem 0;
}

.o-artist-chat-footer {
    background-color: #202020;
    border-radius: 0 0 .5rem .5rem;
    display: flex;
    padding: 1rem;
}

.o-artist-chat-footer input {
    background-color: #2a292e;
    border: 0;
    color: #8e8e8e;
    flex-grow: 1;
    padding: 1rem;
}

.o-artist-chat-button {
    padding: 10px 15px;
}
