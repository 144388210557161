.o-notification-create-user-element {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.o-notification-create-user-count {
    padding-top: .6rem;
}

.o-notification-create .error {
    color: red;
    font-size: 1rem !important;
    position: relative;
    top: -1rem !important;
}