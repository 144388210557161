.o-cgu-detail-label { 
    font-size: 1rem !important;
    font-weight: bold !important;
    margin-bottom: 1rem !important;
}

.o-cgu-detail-error {
    margin-top: 1rem !important;
    color: red;
    font-size: 1rem !important;
}