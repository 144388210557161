.o-sidemenu {
    background: var(--side-menu-bg);
    color: #ffffff;
    width: 100%;
}

.o-sidemenu-logo {
    filter: invert(100%);
    max-width: 14rem;
    min-width: 5rem;
    margin: .5rem auto 0 auto;
    text-align: center;
    width: 50%;

}

.o-sidemenu-lastlogin {
    filter: brightness(0.8);
    color: #ffffff;
    font-size: .8rem;
    text-align: center;
}

.o-sidemenu ul {
    padding: 0;
}
