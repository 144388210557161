.o-artists-page-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: .5rem;
}

.o-artists-page-type {
    font-size: 1.5rem;
    text-align: right;
}
