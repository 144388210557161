.relative {
    position: relative;
}

.o-input-text-container {
    border: 2px solid #000000;
    padding: 0;
    border-radius: .2rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
}

.o-input-text-container input {
    border: 0 none transparent;
    padding: .8rem 1.5rem;
    border-radius: 0;
    font-size: 1rem;
    flex-grow: 1;
}

.o-input-text-container .o-input-text-tags + input {
    padding: .8rem 1.5rem .8rem 0;
}

.o-input-text-container input:focus {
    outline: transparent;
}

.o-input-text-completion {
    list-style: none;
    background: var(--page-container-body-bg);
    margin-top: 0.1rem;
    border: 2px solid #000000;
    padding: .8rem 1.5rem;
    border-radius: .2rem;
    max-height: 150px;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    top: 72px;
    z-index: 12;
    box-shadow: 0 20px 25px -5px rgba(0,0,0,.1),0 10px 10px -5px rgba(0,0,0,.04) !important;
}

.o-input-text-completion li {
    padding: 5px 0;
    cursor: pointer;
    text-align: left;
}

.o-input-text-tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
}

.o-input-text-tags li {
    padding: 4px;
    background: white;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    margin: 0 .5rem 0 0;
    border-radius: .2rem;
}

.o-input-text-tags li:first-child {
    margin: 0 .5rem;
}

.o-input-text-tags li i {
    cursor: pointer;
}

.o-input-text-label {
    height: 1.3rem;
}

.o-input-text-tags.bottom li {
    background: transparent;
    border: 0;
    color: #000000;
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 1rem !important;
    width: 100%;
}

.o-input-text-tags.bottom li i {
    color: var(--primary-color);
}

.o-input-text-tags.bottom li:first-child {
    margin: 0;
}
