.o-image-upload {
    display: flex;
    flex-direction: column;
}

.o-image-upload p {
    text-align: center;
}

.o-image-upload-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.o-image-upload-picture {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: solid 1px #cccccc;
    height: 0;
    width: 100%;
}

.o-image-upload-picture.banner {
    width: 18rem;
    height: 10rem;
}
