.o-banner-modify .error {
    color: red;
    font-size: 1rem !important;
    position: relative;
    top: -1rem !important;
}

.o-banner-modify .p-dialog {
    height: 24rem;
}

.o-banner-modify .p-dialog .p-dialog-content {
    height: 100%;
}

.o-banner-modify-content {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.o-banner-modify .o-type {
    font-weight: bold;
    margin: .5rem 0;
    text-align: center;
    text-transform: uppercase;
}

.o-banner-modify-content .o-title {
    font-weight: bold;
}

.o-banner-modify-content .o-picture {
    margin-bottom: .5rem;
    height: 10rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}