.o-button-rounded button {
    padding: 6px 23px;
    background: var(--button-bg);
    color: white;
    border: 1px solid var(--button-b);
    border-radius: 30px;
    font-weight: 300;
    font-size: 14px;
    margin: 10px 0;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    cursor: pointer;
    outline: none;
}

.o-button-rounded button:hover {
    background: white;
    color: var(--button-bg);
}

.o-button-rounded button:disabled,
.o-button-rounded button[disabled] {
    background: var(--button-disabled-bg);
    border: solid 1px var(--button-disabled-b);
    cursor: not-allowed;
    color: white;
}

.o-button-rounded button.secondary {
    background: white;
    border: 1px solid var(--button-b);
    color: var(--button-b);
}

.o-button-rounded button.secondary:hover {
    background: var(--button-bg);
    color: white;
}

.o-button-rounded button.secondary:disabled,
.o-button-rounded button[disabled].secondary {
    background:  white;
    border: solid 1px var(--button-disabled-b);
    cursor: not-allowed;
    color:var(--button-disabled-bg);
}