@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap');

html, body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    height: 100% !important;
    margin: 0;
    color: var(--base-text-color);
}

#root {
    height: 100% !important;
}

:focus {
    outline: var(--primary-color) auto 1px;
}

:root {
    /* config/base */
    --primary-color: #ff006a;
    --primary-color-lighten: rgb(255, 86, 156);
    --primary-color-darken: rgb(131, 0, 55);
    --base-text-color: #202125;
    --disabled-color: #636369;

    --error-c: #ff3c00;

    --warning-bg: #ff3c00;
    --warning-c: #ffffff;

    --font-size-xxs: .6rem;
    --font-size-xs: .8rem;
    --font-size-s: .9rem;
    --font-size-m: 1rem;
    --font-size-l: 1.2rem;
    --font-size-xl: 1.5rem;

    /* vars */
    --account-icon-bg: var(--primary-color);
    --account-picture-b: var(--primary-color);
    --button-b: var(--primary-color);
    --button-b-darken: var(--primary-color-darken);
    --button-b-lighten: var(--primary-color-lighten);
    --button-bg: var(--primary-color);
    --button-bg-darken: var(--primary-color-darken);
    --button-bg-lighten: var(--primary-color-lighten);
    --button-c: #ffffff;
    --button-danger-b: #ff3c00;
    --button-danger-bg: #ff3c00;
    --button-disabled-b: var(--disabled-color);
    --button-disabled-bg: var(--disabled-color);
    --button-secondary-c: var(--primary-color);
    --button-secondary-b: var(--primary-color);
    --button-secondary-bg: transparent;
    --button-icon-c: #ffffff;
    --button-icon-secondary-c: var(--primary-color);
    --dialog-bg: #f2f2f2;
    --page-container-body-bg: #f5f5f5;
    --page-container-side-bg: #000000;
    --panel-children-bg: #ffffff;
    --panel-title-bg: var(--base-text-color);
    --panel-title-c: #f5f5f5;
    --side-menu-bg: var(--base-text-color);
    --submit-button-bg: var(--primary-color);
    --submit-button-b: var(--primary-color-lighten);
    --submit-button-c: #ffffff;
    --submit-button-bg-hover: #ffffff;
    --submit-button-c-hover: var(--primary-color);
    --table-tbody-tr-b: var(--base-text-color);
    --table-tbody-tr-bg: #ffffff;
    --table-tbody-tr-c: var(--base-text-color);
    --table-thead-tr-bg: var(--base-text-color);
    --table-thead-tr-c: #f5f5f5;
    --table-tbody-td-b: var(--base-text-color);
}

/* Error */
.error {
    color: var(--error-c);
    font-size: 1rem !important;
    position: relative;
    top: -1rem !important;
}

/* Table */
.o-table {
    width: 100%;
}

.o-table table {
    border-spacing: 0;
    min-width: 100%;
}

.o-table th {
    height: 3rem;
    min-width: .5rem;
    padding: 0 .5rem;
    text-align: center;
}

.o-table th:first-child {
    border-radius: .5rem 0 0 0;
}

.o-table th:last-child {
    border-radius: 0 .5rem 0 0;
}

.o-table tr {
    height: 3rem;
}

.o-table thead tr {
    background-color: var(--table-thead-tr-bg);
    color: var(--table-thead-tr-c);
}

.o-table tbody tr {
    background-color: var(--table-tbody-tr-bg);
    border-bottom: solid 1px var(--table-tbody-tr-b);
    color: var(--table-tbody-tr-c);
}

.o-table .o-table-draggable {
    cursor: move;
    font-size: 1.2rem;
    text-align: left;
}

.o-table td {
    border-bottom: solid 1px var(--table-tbody-td-b);
    padding: 0 .5rem;
    text-align: center;
}

.o-table .min1 {
    min-width: 2rem;
}

.o-table .min2 {
    min-width: 4rem;
}

.o-table .min3 {
    min-width: 6rem;
}

.o-table .min5 {
    min-width: 10rem;
}

.o-table tr:last-child td {
    border-bottom: 0;
}

.o-table .left {
    text-align: left;
}

.o-table .right {
    text-align: right;
}

.o-table .o-table-actions {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.o-table .o-table-actions-alt i {
    margin-left: .5rem;
}

.o-table-responsive .p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 80em) {
    .o-table-responsive .p-datatable.p-datatable-responsive .p-datatable-thead > tr > th,
    .o-table-responsive .p-datatable.p-datatable-responsive .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .o-table-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;
        text-align: left !important;
    }

    .o-table-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4rem;
        font-weight: bold;
        text-align: left;
    }

    .o-table-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:last-child {
        border-bottom: 1px solid var(--surface-d);
    }
}