.o-static-text {
    display: flex;
    flex-direction: column;
    color: #000000;
    margin-bottom: 1rem;
}

.o-static-text label {
    margin-bottom: .4rem;
}

.o-static-text span {
    min-height: 1rem;
}

.o-static-text-value {
    background-color:rgba(239, 239, 239, 0.3);
    border: 2px solid #000000;
    border-radius: .2rem;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    justify-content: center;
    padding: .8rem 1.5rem;
    overflow-wrap: break-word;
}
