.o-account-page-picture {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.o-account-page-picture img {
    border: solid 2px #c0c0c0;
    border-radius: 4.5rem;
    height: 9rem;
    margin-top: 1.8rem;
    width: 9rem;
}
