.o-post-modify-views {
    display: flex;
    align-items: center;
    justify-content: center;
}

.o-post-modify-views .value {
    font-weight: bold;
    margin-right: .3rem;
}

.o-post-modify-page .o-media-upload,
.o-post-modify-page .o-media-refresh {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.o-post-modify-page .o-media-upload .o-input-file {
    margin: 0;
}

.o-post-modify-page .o-dialog-upload-video-name {
    margin-top: 1rem !important;
    font-size: var(--font-size-l);
    font-weight: bold;
}

.o-post-modify-page .o-media-refresh .o-button-icon {
    border-radius: 3px;
    height: 2.05rem;
    width: 5.25rem;
}

.o-post-modify-page .o-steps ul {
    display: flex;
    justify-content: space-around;
}

.o-post-modify-page .error {
    position: initial;
    top: 0;
    margin-top: 1rem;
}

.o-post-modify-page .o-live-setup {
    display: flex;
    align-items: center;
}

.o-post-modify-page .o-image-upload-container .live-picture {
    padding: 0 2rem;
}

.o-post-modify-page .o-input-text-container {
    padding: 0 1.5rem;
}