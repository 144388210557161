.o-input-multiselect {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.o-input-multiselect-label {
    margin-bottom: .4rem;
}

.o-input-multiselect .p-multiselect {
    border-width: 2px;
    border-style: solid;
    border-color: var(--base-text-color);
    padding: 0.3rem 1.5rem;
    border-radius: .2rem;
    min-width: 12rem;
}

.o-input-multiselect .p-multiselect-token {
    border-width: 1px;
    border-style: solid;
    border-color: var(--button-secondary-b) !important;
    background: var(--button-secondary-bg) !important;
    color: var(--button-secondary-c) !important;
}

.o-input-multiselect .p-multiselect:not(.p-disabled).p-focus {
    border-color: var(--button-b) !important;
    box-shadow: unset !important;
}

.o-input-multiselect .p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--button-b) !important;
    background: var(--button-bg) !important;
}

.o-input-multiselect .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: var(--base-text-color);
    background: #ffffff;
}
