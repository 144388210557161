.o-sidemenu-item {
    align-items: center;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    font-size: 1.1rem;
    font-weight: bold;
    list-style-type: none;
    padding: .6rem 2rem;
    text-transform: uppercase;
}

.o-sidemenu-item:hover {
    background-color: #c0c0c0;
    color: #000000;
}

.o-sidemenu-item.active {
    background-color: #ffffff;
    color: #000000;
}

.o-sidemenu-item-label {
    margin-left: 1.2rem;
}
