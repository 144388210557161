.o-artist-new-box {
    border-radius: .5rem;
    height: 15rem;
    width: 15rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 1rem .5rem .5rem .5rem;
    text-align: center;
    cursor: pointer;
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.o-artist-new-box:hover {
    border-color: var(--primary-color);
    background-color: white;
    color: var(--primary-color);
}

.o-artist-new-box-picture {
    font-size: 6rem;
}

.o-artist-new-box-name {
    margin-top: .5rem;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
}
