.o-input-file {
    color: #000000;
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.o-input-file label {
    margin-bottom: .4rem;
}

.o-input-file .p-button {
    background-color: var(--button-bg);
    color: white;
    border: solid 1px var(--button-b);
}

.o-input-file .p-fileupload-choose:not(.p-disabled):hover {
    background-color: white;
    color: var(--button-bg);
    border: solid 1px var(--button-b);
}

.o-input-file.secondary .p-button {
    background-color: white;
    color: var(--button-bg);
}

.o-input-file.secondary .p-fileupload-choose:not(.p-disabled):hover {
    background-color: var(--button-bg);
    color: white;
}

.o-input-file.disabled .p-button {
    background: var(--button-disabled-bg) !important;
    border: solid 1px var(--button-disabled-b) !important;
    cursor: not-allowed !important;
    color: white !important;
}