.p-dialog {
    background-color: var(--dialog-bg);
    text-align: center;
    padding: 2em 5em 1em 5em !important;
    width: 40em !important;
}

.o-dialog-buttons {
    margin-top: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.p-dialog-content {
    background-color: unset !important;
    border: unset !important;
}
