.o-button-icon {
    display: flex;
    background-color: var(--button-bg);
    color: white;
    border: solid 1px var(--button-b);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;

    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.o-button-icon:hover  {
    background-color: white;
    color: var(--button-bg);
    border: solid 1px var(--button-b);
}

.o-button-icon:hover i  {
    color: var(--button-bg) !important;
}

.o-button-icon.danger {
    background-color: var(--button-danger-bg);
    border: solid 1px var(--button-danger-b);
}

.o-button-icon.secondary {
    background: var(--button-secondary-bg);
    border: solid 1px var(--button-secondary-b);
}

.o-button-icon.disabled {
    background: var(--button-disabled-bg);
    border: solid 1px var(--button-disabled-b);
    cursor: default;
}

.o-button-icon.disabled:hover {
    filter: none;
}

.o-button-icon i {
    color: var(--button-icon-c);
}

.o-button-icon i.secondary {
    color: var(--button-icon-secondary-c);
}
