.o-users-table .fa-check {
    color: #00ff3c;
}

.o-users-table .fa-moon {
    color: #2e3e57;
}

.o-users-table .fa-times {
    color: #ff3c00;
}

.o-users-table .flag {
    height: 1rem;
}
