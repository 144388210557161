.o-textarea {
    color: #000000;
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.o-textarea label {
    margin-bottom: .4rem;
}

.o-textarea textarea {
    border: 2px solid #000000;
    padding: .8rem 1.5rem;
    border-radius: .2rem;
    font-size: 1rem;
}
