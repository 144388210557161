.o-posts-page-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: .5rem;
}

.o-posts-page-new  {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 1rem;
}

.o-posts-page-new  .o-submit-button {
    padding-bottom: .5rem;
}

.o-posts {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}
