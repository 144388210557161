.o-input-datetime2-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.o-input-datetime2-label {
    margin-bottom: .4rem;
}

span.p-calendar {
    width: 100%;
}

.p-calendar .p-datepicker {
    min-width: inherit !important;
}

.o-input-datetime2 .p-inputtext {
    border: 2px solid #132542 !important;
    padding: .8rem 1.5rem !important;
    border-radius: .2rem !important;
    font-size: 1rem !important;
    width: 100%;
}

.o-input-datetime2-value {
    display: flex;
    flex-direction: row;
}

.o-input-datetime2-errors {
    display: flex;
    color: red !important;
    padding-top: .5rem !important;
    font-size: 1rem !important;
}

.o-input-datetime2-date .p-inputtext {
    width: 9rem;
}

.o-input-datetime2-time .p-inputtext {
    margin-left: 1rem;
    width: 6rem;
}

.o-input-datetime2 .error {
    color: red;
    font-size: 1rem !important;
    top: 0 !important;
}
