.o-page-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.o-page-title-back {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
}

.o-page-title-back i {
    cursor: pointer;
}