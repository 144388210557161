.o-login {
    align-items: center;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 100vh;
    overflow: hidden;
    padding: 0;
    width: 100%;
}

.o-login-wrapper {
    align-items: center;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 30rem;
    padding: 1rem .5rem 2rem .5rem;
    text-align: center;
    width: 20rem;
}

.o-login-logo {
    width: 60%;
    max-width: 15rem;
    min-width: 10rem;
    text-align: center;
    margin: .5rem 0 2rem 0;
}

.o-login-form {
    width: 60%;
    max-width: 25rem;
    min-width: 20rem;
    padding: 0 1rem;
}

.o-login-link {
    font-size: .9rem;
    cursor: pointer;
}

.o-login-link:hover {
    text-decoration: underline;
}

.o-login-loader {
    align-items: center;
    display: flex;
    height: 30rem;
    opacity: 0.5;
    justify-content: center;
    position: absolute;
    text-align: center;
    z-index: 1000;
}

.o-login-copyright {
    font-size: .8rem;
    height: 2rem;
    margin-top: 1rem;
}

.o-login-form .p-inputtext,
.o-login-form button {
    width: 100%;
}

.o-login-form button {
    background-color: var(--button-bg);
    color: white;
    border: solid 1px var(--button-b);
    position: relative;

    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.o-login-form button:enabled:hover {
    background-color: white;
    color: var(--button-bg);
    border: solid 1px var(--button-b);
}
