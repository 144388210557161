.o-input-multiselect-inline {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.o-input-multiselect-inline-label {
    margin-bottom: .4rem;
}

.o-input-multiselect-inline .p-button {
    font-size: 1rem;
    font-weight: bold;
    padding: .3rem .5rem;
    background-color: white !important;
    color: var(--button-bg) !important;
    border-color: var(--button-b) !important;
    box-shadow: unset !important;

    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}
.o-input-multiselect-inline .p-button:hover {
    background-color: var(--button-bg) !important;
    border-color: var(--button-b) !important;
    color: white !important;
}

.o-input-multiselect-inline .p-highlight {
    background-color: var(--button-bg) !important;
    border-color: var(--button-b) !important;
    color: white !important;

    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.o-input-multiselect-inline .p-highlight:hover {
    background-color: white !important;
    color: var(--button-bg) !important;
    border-color: var(--button-b) !important;
}

.o-input-multiselect-inline .p-button:first-child {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
}

.o-input-multiselect-inline .p-button:last-child {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
}
