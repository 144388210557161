.o-users-filter-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.o-users-filter-container .o-input-select,
.o-users-filter-container .o-input-text,
.o-users-filter-container .o-input-datetime2 {
    margin-right: 1rem;
}

.o-users-table-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding-right: 1rem;
    margin-bottom: 1rem;
}

.o-users-paginator-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.o-users-paginator-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 1rem;
}

.o-users-page .p-paginator {
    border: 0;
    text-align: left;
    background-color: transparent !important;
}

.o-users-page .p-paginator .p-highlight {
    background-color: #c0c0c0 !important;
}