.o-post-type {
    background-color: var(--button-bg);
    border-color: var(--button-b);
    border-radius: .5rem;
    color: white;
    display: inline-block;
    font-size: 1rem;
    font-weight: bold;
    padding: .8rem 1.5rem;
}
