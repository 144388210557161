@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap);
.o-accountmenu {
    width: 100%;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 3px 3px 3px #c0c0c0;
    overflow: hidden;
    z-index: 1000;
}

.o-accountmenu ul {
    margin: 0;
    padding: 0;
}

.o-accountmenu ul:first-child {
    border-radius: 5px 5px 0 0;
}

.o-accountmenu ul:last-child {
    border-radius: 5px 5px 0 0;
}

.o-accountmenu li {
    cursor: pointer;
    font-weight: bold;
    list-style-type: none;
    padding: .5em;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
}

.o-accountmenu li:hover {
    color: var(--button-c);
    background: var(--button-bg);
}

.o-account {
    height: 5.2rem;
}

.o-account-avatar {
    height: 5.2rem;
}

.o-account-picture {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 2.6rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 0 auto;
    width: 5.2rem;
    height: 5.2rem;
    border: solid 2px var(--account-picture-b);
}

.o-account-icon {
    -webkit-align-items: center;
            align-items: center;
    bottom: 1.7rem;
    border-radius: .5rem;
    color: #000000;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    height: 1.2rem;
    -webkit-justify-content: center;
            justify-content: center;
    left: calc(50% + 1.5rem);
    position: relative;
    width: 1.2rem;
    background: var(--account-icon-bg);
}

.o-account-icon:hover {
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8);
}

.o-account-menu {
    position: relative;
    bottom: .4rem;
    color: #000000;
    left: calc(50% + 1.5rem);
    transition: all 100ms ease-out;
    width: 0;
}

.o-account-menu.active {
    transition: all 100ms ease-out;
    width: 12rem;
}

.o-sidemenu-bloc {

    background-color: #ffffff;
    list-style-type: none;
    height: 1px;
    margin: .5rem 0;
}

.o-sidemenu-item {
    -webkit-align-items: center;
            align-items: center;
    color: #ffffff;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    font-size: 1.1rem;
    font-weight: bold;
    list-style-type: none;
    padding: .6rem 2rem;
    text-transform: uppercase;
}

.o-sidemenu-item:hover {
    background-color: #c0c0c0;
    color: #000000;
}

.o-sidemenu-item.active {
    background-color: #ffffff;
    color: #000000;
}

.o-sidemenu-item-label {
    margin-left: 1.2rem;
}

.o-sidemenu {
    background: var(--side-menu-bg);
    color: #ffffff;
    width: 100%;
}

.o-sidemenu-logo {
    -webkit-filter: invert(100%);
            filter: invert(100%);
    max-width: 14rem;
    min-width: 5rem;
    margin: .5rem auto 0 auto;
    text-align: center;
    width: 50%;

}

.o-sidemenu-lastlogin {
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8);
    color: #ffffff;
    font-size: .8rem;
    text-align: center;
}

.o-sidemenu ul {
    padding: 0;
}

.o-page-container {
    width: 100%;
}

.o-page-container-body {
    background-color: var(--page-container-body-bg);
}

.o-page-container-side {
    background-color: var(--side-menu-bg);
}

.o-app {
    -webkit-align-items: stretch;
            align-items: stretch;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    min-height: 100%;
}

.o-app:after {
    content: ""; /* Important, sinon l'élément n'est pas généré. */
    display: table;
    clear: both;
}

a {
    color: var(--primary-color) !important;
}

html, body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    height: 100% !important;
    margin: 0;
    color: #202125;
    color: var(--base-text-color);
}

#root {
    height: 100% !important;
}

:focus {
    outline: #ff006a auto 1px;
    outline: var(--primary-color) auto 1px;
}

:root {
    /* config/base */
    --primary-color: #ff006a;
    --primary-color-lighten: rgb(255, 86, 156);
    --primary-color-darken: rgb(131, 0, 55);
    --base-text-color: #202125;
    --disabled-color: #636369;

    --error-c: #ff3c00;

    --warning-bg: #ff3c00;
    --warning-c: #ffffff;

    --font-size-xxs: .6rem;
    --font-size-xs: .8rem;
    --font-size-s: .9rem;
    --font-size-m: 1rem;
    --font-size-l: 1.2rem;
    --font-size-xl: 1.5rem;

    /* vars */
    --account-icon-bg: var(--primary-color);
    --account-picture-b: var(--primary-color);
    --button-b: var(--primary-color);
    --button-b-darken: var(--primary-color-darken);
    --button-b-lighten: var(--primary-color-lighten);
    --button-bg: var(--primary-color);
    --button-bg-darken: var(--primary-color-darken);
    --button-bg-lighten: var(--primary-color-lighten);
    --button-c: #ffffff;
    --button-danger-b: #ff3c00;
    --button-danger-bg: #ff3c00;
    --button-disabled-b: var(--disabled-color);
    --button-disabled-bg: var(--disabled-color);
    --button-secondary-c: var(--primary-color);
    --button-secondary-b: var(--primary-color);
    --button-secondary-bg: transparent;
    --button-icon-c: #ffffff;
    --button-icon-secondary-c: var(--primary-color);
    --dialog-bg: #f2f2f2;
    --page-container-body-bg: #f5f5f5;
    --page-container-side-bg: #000000;
    --panel-children-bg: #ffffff;
    --panel-title-bg: var(--base-text-color);
    --panel-title-c: #f5f5f5;
    --side-menu-bg: var(--base-text-color);
    --submit-button-bg: var(--primary-color);
    --submit-button-b: var(--primary-color-lighten);
    --submit-button-c: #ffffff;
    --submit-button-bg-hover: #ffffff;
    --submit-button-c-hover: var(--primary-color);
    --table-tbody-tr-b: var(--base-text-color);
    --table-tbody-tr-bg: #ffffff;
    --table-tbody-tr-c: var(--base-text-color);
    --table-thead-tr-bg: var(--base-text-color);
    --table-thead-tr-c: #f5f5f5;
    --table-tbody-td-b: var(--base-text-color);
}

/* Error */
.error {
    color: #ff3c00;
    color: var(--error-c);
    font-size: 1rem !important;
    position: relative;
    top: -1rem !important;
}

/* Table */
.o-table {
    width: 100%;
}

.o-table table {
    border-spacing: 0;
    min-width: 100%;
}

.o-table th {
    height: 3rem;
    min-width: .5rem;
    padding: 0 .5rem;
    text-align: center;
}

.o-table th:first-child {
    border-radius: .5rem 0 0 0;
}

.o-table th:last-child {
    border-radius: 0 .5rem 0 0;
}

.o-table tr {
    height: 3rem;
}

.o-table thead tr {
    background-color: #202125;
    background-color: var(--table-thead-tr-bg);
    color: #f5f5f5;
    color: var(--table-thead-tr-c);
}

.o-table tbody tr {
    background-color: #ffffff;
    background-color: var(--table-tbody-tr-bg);
    border-bottom: solid 1px #202125;
    border-bottom: solid 1px var(--table-tbody-tr-b);
    color: #202125;
    color: var(--table-tbody-tr-c);
}

.o-table .o-table-draggable {
    cursor: move;
    font-size: 1.2rem;
    text-align: left;
}

.o-table td {
    border-bottom: solid 1px #202125;
    border-bottom: solid 1px var(--table-tbody-td-b);
    padding: 0 .5rem;
    text-align: center;
}

.o-table .min1 {
    min-width: 2rem;
}

.o-table .min2 {
    min-width: 4rem;
}

.o-table .min3 {
    min-width: 6rem;
}

.o-table .min5 {
    min-width: 10rem;
}

.o-table tr:last-child td {
    border-bottom: 0;
}

.o-table .left {
    text-align: left;
}

.o-table .right {
    text-align: right;
}

.o-table .o-table-actions {
    height: 100%;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.o-table .o-table-actions-alt i {
    margin-left: .5rem;
}

.o-table-responsive .p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 80em) {
    .o-table-responsive .p-datatable.p-datatable-responsive .p-datatable-thead > tr > th,
    .o-table-responsive .p-datatable.p-datatable-responsive .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .o-table-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;
        text-align: left !important;
    }

    .o-table-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4rem;
        font-weight: bold;
        text-align: left;
    }

    .o-table-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:last-child {
        border-bottom: 1px solid var(--surface-d);
    }
}
.o-input-select {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    color: #000000;
    margin-bottom: 1rem;
}

.o-input-select label {
    margin-bottom: .4rem;
}

.o-input-select input {
    border: 2px solid #000000;
    padding: .8rem 1.5rem;
    border-radius: .2rem;
    font-size: 1rem;
}

.o-input-select {
    color: #000000;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-bottom: 1rem;
}

.o-input-select label {
    margin-bottom: .4rem;
}

.o-input-select select {
    border: 2px solid #000000;
    padding: .8rem 1.5rem;
    border-radius: .2rem;
    font-size: 1rem;
    min-width: 12rem;
}

.o-input-text {
    color: #000000;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-bottom: 1rem;
}

.o-input-text label {
    margin-bottom: .4rem;
}

.o-input-text input {
    border: 2px solid #000000;
    padding: .8rem 1.5rem;
    border-radius: .2rem;
    font-size: 1rem;
}

.o-page-title-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.o-page-title-back {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-align-content: center;
            align-content: center;
}

.o-page-title-back i {
    cursor: pointer;
}
.o-page {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100%;
    padding: 0 2rem;
    overflow: auto;
}

.o-page-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -5rem;
    opacity: .5;
    z-index: 1000 !important;
}

.o-page-children {
    padding-bottom: 2rem;
}

.o-panel {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    /* height: 100%; */
    margin-bottom: 2rem;
}

.o-panel-title {
    -webkit-align-items: center;
            align-items: center;
    background-color: var(--panel-title-bg);
    border-radius: 1rem 1rem 0 0;
    color: var(--panel-title-c);
    display: -webkit-flex;
    display: flex;
    font-weight: bold;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 1rem 2rem;
    text-transform: uppercase;
}

.o-panel-title-icon {
    cursor: pointer;
}

.o-panel-children {
    background-color: var(--panel-children-bg);
    padding: 1rem 2rem;
}

.o-panel-children-active {
    transition: opacity 0.5s linear;
    -webkit-transform: translate(0);
            transform: translate(0);
    opacity: 1;
}

.o-panel-children-collapsed {
    transition: all 0.5s linear;
    opacity: 0;
    height: 0;
    border: 0;
    padding: 0;
}

.o-static-text {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    color: #000000;
    margin-bottom: 1rem;
}

.o-static-text label {
    margin-bottom: .4rem;
}

.o-static-text span {
    min-height: 1rem;
}

.o-static-text-value {
    background-color:rgba(239, 239, 239, 0.3);
    border: 2px solid #000000;
    border-radius: .2rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-size: 1rem;
    -webkit-justify-content: center;
            justify-content: center;
    padding: .8rem 1.5rem;
    overflow-wrap: break-word;
}

.o-submit-button button {
    background-color: var(--button-bg);
    color: white;
    border: solid 1px var(--button-b);

    border-radius: .2rem;
    cursor: pointer;
    width: 100%;
    font-size: 1rem;
    font-weight: bold;
    padding: .8rem 1.5rem;
    text-transform: uppercase;
    transition: all 0.4s;
}

.o-submit-button button:hover {
    background-color: white;
    color: var(--button-bg);
    border: solid 1px var(--button-b);
}

.o-submit-button button:disabled,
.o-submit-button button[disabled] {
    background: var(--button-disabled-bg);
    border: solid 1px var(--button-disabled-b);
    cursor: not-allowed;
    color: white;
}

.o-submit-button.secondary button {
    background-color: white;
    color: var(--button-bg);
    border: solid 1px var(--button-b);
}

.o-submit-button.secondary button:hover {
    background-color: var(--button-bg);
    color: white;
    border: solid 1px var(--button-b);
}

.o-account-page-picture {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
}

.o-account-page-picture img {
    border: solid 2px #c0c0c0;
    border-radius: 4.5rem;
    height: 9rem;
    margin-top: 1.8rem;
    width: 9rem;
}

.o-cgu-detail-label { 
    font-size: 1rem !important;
    font-weight: bold !important;
    margin-bottom: 1rem !important;
}

.o-cgu-detail-error {
    margin-top: 1rem !important;
    color: red;
    font-size: 1rem !important;
}
.o-button-rounded button {
    padding: 6px 23px;
    background: var(--button-bg);
    color: white;
    border: 1px solid var(--button-b);
    border-radius: 30px;
    font-weight: 300;
    font-size: 14px;
    margin: 10px 0;
    transition: all 0.2s ease-in;
    cursor: pointer;
    outline: none;
}

.o-button-rounded button:hover {
    background: white;
    color: var(--button-bg);
}

.o-button-rounded button:disabled,
.o-button-rounded button[disabled] {
    background: var(--button-disabled-bg);
    border: solid 1px var(--button-disabled-b);
    cursor: not-allowed;
    color: white;
}

.o-button-rounded button.secondary {
    background: white;
    border: 1px solid var(--button-b);
    color: var(--button-b);
}

.o-button-rounded button.secondary:hover {
    background: var(--button-bg);
    color: white;
}

.o-button-rounded button.secondary:disabled,
.o-button-rounded button[disabled].secondary {
    background:  white;
    border: solid 1px var(--button-disabled-b);
    cursor: not-allowed;
    color:var(--button-disabled-bg);
}
.p-dialog {
    background-color: var(--dialog-bg);
    text-align: center;
    padding: 2em 5em 1em 5em !important;
    width: 40em !important;
}

.o-dialog-buttons {
    margin-top: 1rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
}

.p-dialog-content {
    background-color: unset !important;
    border: unset !important;
}

.o-input-multiselect-inline {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.o-input-multiselect-inline-label {
    margin-bottom: .4rem;
}

.o-input-multiselect-inline .p-button {
    font-size: 1rem;
    font-weight: bold;
    padding: .3rem .5rem;
    background-color: white !important;
    color: var(--button-bg) !important;
    border-color: var(--button-b) !important;
    box-shadow: unset !important;
    transition: all 0.4s;
}
.o-input-multiselect-inline .p-button:hover {
    background-color: var(--button-bg) !important;
    border-color: var(--button-b) !important;
    color: white !important;
}

.o-input-multiselect-inline .p-highlight {
    background-color: var(--button-bg) !important;
    border-color: var(--button-b) !important;
    color: white !important;
    transition: all 0.4s;
}

.o-input-multiselect-inline .p-highlight:hover {
    background-color: white !important;
    color: var(--button-bg) !important;
    border-color: var(--button-b) !important;
}

.o-input-multiselect-inline .p-button:first-child {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
}

.o-input-multiselect-inline .p-button:last-child {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
}

.o-artist-box {
    border-radius: .5rem;
    height: 15rem;
    width: 15rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 1rem .5rem .5rem .5rem;
    text-align: center;
    color: white;
    overflow: hidden;
    background-color: var(--base-text-color);
    border: 2px solid var(--base-text-color);
}

.o-artist-box:hover {
    border-color: var(--primary-color);
    cursor: pointer;
}

.o-artist-box-picture {
    margin-bottom: 1rem;
    height: 10rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.o-artist-box-name {
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
}

.o-artist-box-info {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-content: stretch;
            align-content: stretch;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    font-size: small;
}

.o-artist-new-box {
    border-radius: .5rem;
    height: 15rem;
    width: 15rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 1rem .5rem .5rem .5rem;
    text-align: center;
    cursor: pointer;
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.o-artist-new-box:hover {
    border-color: var(--primary-color);
    background-color: white;
    color: var(--primary-color);
}

.o-artist-new-box-picture {
    font-size: 6rem;
}

.o-artist-new-box-name {
    margin-top: .5rem;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
}

.o-artist-boxes {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.o-users-table .fa-check {
    color: #00ff3c;
}

.o-users-table .fa-moon {
    color: #2e3e57;
}

.o-users-table .fa-times {
    color: #ff3c00;
}

.o-users-table .flag {
    height: 1rem;
}

.p-paginator {
    border: 0 none;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background-color: transparent;
    border: 0 none;
    color: var(--button-bg);
}

.o-artists-page-footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: .5rem;
}

.o-artists-page-type {
    font-size: 1.5rem;
    text-align: right;
}

.o-artist-chat {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.o-artist-chat-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 50rem;
    width: 30rem;
}

.o-artist-chat-header {
    background: #202020;
    border-radius: .5rem .5rem 0 0;
    color: white;
    padding: 1rem;
}

.o-artist-chat-list {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    background-color: #191a1e;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    overflow: auto;
    padding: 1rem;
}

.o-artist-chat-message {
    background-color: #0e0f11;
    border-radius: 1rem 1rem 1rem 0;
    color: #ffffff;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-top: .5rem;
    width: 100%;
}

.o-artist-chat-avatar {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    height: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    width: 6rem;
}

.o-artist-chat-picture {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 1.5rem;
    margin: 0 auto;
    width: 3rem;
    height: 3rem;
}

.o-artist-chat-text {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    padding: .5rem .5rem .5rem 0;
    width: 100%;
}

.o-artist-chat-text .footer,
.o-artist-chat-text .header {
    color: #8e8e8e;
    font-size: .9rem;
}

.o-artist-chat-text .body {
    margin: .3rem 0;
}

.o-artist-chat-footer {
    background-color: #202020;
    border-radius: 0 0 .5rem .5rem;
    display: -webkit-flex;
    display: flex;
    padding: 1rem;
}

.o-artist-chat-footer input {
    background-color: #2a292e;
    border: 0;
    color: #8e8e8e;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    padding: 1rem;
}

.o-artist-chat-button {
    padding: 10px 15px;
}


.o-textarea {
    color: #000000;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.o-textarea label {
    margin-bottom: .4rem;
}

.o-textarea textarea {
    border: 2px solid #000000;
    padding: .8rem 1.5rem;
    border-radius: .2rem;
    font-size: 1rem;
}

.o-image-upload {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.o-image-upload p {
    text-align: center;
}

.o-image-upload-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.o-image-upload-picture {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: solid 1px #cccccc;
    height: 0;
    width: 100%;
}

.o-image-upload-picture.banner {
    width: 18rem;
    height: 10rem;
}

.p-inputswitch {}
.p-inputswitch.p-inputswitch-checked {}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: var(--primary-color) !important;
}

.relative {
    position: relative;
}

.o-input-text-container {
    border: 2px solid #000000;
    padding: 0;
    border-radius: .2rem;
    font-size: 1rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.o-input-text-container input {
    border: 0 none transparent;
    padding: .8rem 1.5rem;
    border-radius: 0;
    font-size: 1rem;
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.o-input-text-container .o-input-text-tags + input {
    padding: .8rem 1.5rem .8rem 0;
}

.o-input-text-container input:focus {
    outline: transparent;
}

.o-input-text-completion {
    list-style: none;
    background: var(--page-container-body-bg);
    margin-top: 0.1rem;
    border: 2px solid #000000;
    padding: .8rem 1.5rem;
    border-radius: .2rem;
    max-height: 150px;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    top: 72px;
    z-index: 12;
    box-shadow: 0 20px 25px -5px rgba(0,0,0,.1),0 10px 10px -5px rgba(0,0,0,.04) !important;
}

.o-input-text-completion li {
    padding: 5px 0;
    cursor: pointer;
    text-align: left;
}

.o-input-text-tags {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
}

.o-input-text-tags li {
    padding: 4px;
    background: white;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    margin: 0 .5rem 0 0;
    border-radius: .2rem;
}

.o-input-text-tags li:first-child {
    margin: 0 .5rem;
}

.o-input-text-tags li i {
    cursor: pointer;
}

.o-input-text-label {
    height: 1.3rem;
}

.o-input-text-tags.bottom li {
    background: transparent;
    border: 0;
    color: #000000;
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 1rem !important;
    width: 100%;
}

.o-input-text-tags.bottom li i {
    color: var(--primary-color);
}

.o-input-text-tags.bottom li:first-child {
    margin: 0;
}

.o-artist-create .error {
    color: red;
    font-size: 1rem !important;
    position: relative;
    top: -1rem !important;
}

.o-artist-post-list-footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: .5rem;
}

.o-artist-post-list-footer .p-paginator {
    background: #ffffff;
}
.o-artist-modify .error {
    color: red;
    font-size: 1rem !important;
    position: relative;
    top: -1rem !important;
}

.o-artist-modify-action {
    padding: 0 2rem;
}

.o-artist-modify-picture {
    padding: 0 20%;
}
.o-banners-page-footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: .5rem;
}


.o-banner-create .error {
    color: red;
    font-size: 1rem !important;
    position: relative;
    top: -1rem !important;
}

.o-banner-create .p-dialog {
    height: 24rem;
}

.o-banner-create .p-dialog .p-dialog-content {
    height: 100%;
}

.o-banner-create-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
}

.o-banner-create .o-type {
    font-weight: bold;
    margin: .5rem 0;
    text-align: center;
    text-transform: uppercase;
}

.o-banner-create-content .o-type,
.o-banner-create-content .o-title {
    font-weight: bold;
}

.o-banner-create-content .o-picture {
    margin-bottom: .5rem;
    height: 10rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.o-banner-modify .error {
    color: red;
    font-size: 1rem !important;
    position: relative;
    top: -1rem !important;
}

.o-banner-modify .p-dialog {
    height: 24rem;
}

.o-banner-modify .p-dialog .p-dialog-content {
    height: 100%;
}

.o-banner-modify-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
}

.o-banner-modify .o-type {
    font-weight: bold;
    margin: .5rem 0;
    text-align: center;
    text-transform: uppercase;
}

.o-banner-modify-content .o-title {
    font-weight: bold;
}

.o-banner-modify-content .o-picture {
    margin-bottom: .5rem;
    height: 10rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.o-email-validation {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
};

.o-email-validation-container {
    color: #ffffff;
    font-size: 1.5em;
    text-align: center;
}

.o-email-validation-logo {
    width: 60%;
    max-width: 15rem;
    min-width: 10rem;
    text-align: center;
    margin: .5rem 0 2rem 0;
}
.o-input-multiselect {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-bottom: 1rem;
}

.o-input-multiselect-label {
    margin-bottom: .4rem;
}

.o-input-multiselect .p-multiselect {
    border-width: 2px;
    border-style: solid;
    border-color: var(--base-text-color);
    padding: 0.3rem 1.5rem;
    border-radius: .2rem;
    min-width: 12rem;
}

.o-input-multiselect .p-multiselect-token {
    border-width: 1px;
    border-style: solid;
    border-color: var(--button-secondary-b) !important;
    background: var(--button-secondary-bg) !important;
    color: var(--button-secondary-c) !important;
}

.o-input-multiselect .p-multiselect:not(.p-disabled).p-focus {
    border-color: var(--button-b) !important;
    box-shadow: unset !important;
}

.o-input-multiselect .p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--button-b) !important;
    background: var(--button-bg) !important;
}

.o-input-multiselect .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: var(--base-text-color);
    background: #ffffff;
}

.o-item-create .o-submit-button {
    width: 12rem;
}

.o-item-create-picture {
    width: 15rem;
}

.o-item-create .o-submit-button {
    width: 12rem;
}

.o-item-create-picture {
    width: 15rem;
}

.o-new-form-error {
    color: #ff3c00;
    position: relative;
    margin-bottom: 1rem;
}

.o-new-form-title {
    margin-bottom: 1rem;
}

.o-login {
    -webkit-align-items: center;
            align-items: center;
    background-color: #f2f2f2;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    min-height: 100vh;
    overflow: hidden;
    padding: 0;
    width: 100%;
}

.o-login-wrapper {
    -webkit-align-items: center;
            align-items: center;
    background-color: #ffffff;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 30rem;
    padding: 1rem .5rem 2rem .5rem;
    text-align: center;
    width: 20rem;
}

.o-login-logo {
    width: 60%;
    max-width: 15rem;
    min-width: 10rem;
    text-align: center;
    margin: .5rem 0 2rem 0;
}

.o-login-form {
    width: 60%;
    max-width: 25rem;
    min-width: 20rem;
    padding: 0 1rem;
}

.o-login-link {
    font-size: .9rem;
    cursor: pointer;
}

.o-login-link:hover {
    text-decoration: underline;
}

.o-login-loader {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    height: 30rem;
    opacity: 0.5;
    -webkit-justify-content: center;
            justify-content: center;
    position: absolute;
    text-align: center;
    z-index: 1000;
}

.o-login-copyright {
    font-size: .8rem;
    height: 2rem;
    margin-top: 1rem;
}

.o-login-form .p-inputtext,
.o-login-form button {
    width: 100%;
}

.o-login-form button {
    background-color: var(--button-bg);
    color: white;
    border: solid 1px var(--button-b);
    position: relative;
    transition: all 0.4s;
}

.o-login-form button:enabled:hover {
    background-color: white;
    color: var(--button-bg);
    border: solid 1px var(--button-b);
}

.o-notification-create-user-element {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.o-notification-create-user-count {
    padding-top: .6rem;
}

.o-notification-create .error {
    color: red;
    font-size: 1rem !important;
    position: relative;
    top: -1rem !important;
}
.o-posts-page-footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: .5rem;
}

.o-posts-page-new  {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding-bottom: 1rem;
}

.o-posts-page-new  .o-submit-button {
    padding-bottom: .5rem;
}

.o-posts {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.o-button-icon {
    display: -webkit-flex;
    display: flex;
    background-color: var(--button-bg);
    color: white;
    border: solid 1px var(--button-b);
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    transition: all 0.4s;
}

.o-button-icon:hover  {
    background-color: white;
    color: var(--button-bg);
    border: solid 1px var(--button-b);
}

.o-button-icon:hover i  {
    color: var(--button-bg) !important;
}

.o-button-icon.danger {
    background-color: var(--button-danger-bg);
    border: solid 1px var(--button-danger-b);
}

.o-button-icon.secondary {
    background: var(--button-secondary-bg);
    border: solid 1px var(--button-secondary-b);
}

.o-button-icon.disabled {
    background: var(--button-disabled-bg);
    border: solid 1px var(--button-disabled-b);
    cursor: default;
}

.o-button-icon.disabled:hover {
    -webkit-filter: none;
            filter: none;
}

.o-button-icon i {
    color: var(--button-icon-c);
}

.o-button-icon i.secondary {
    color: var(--button-icon-secondary-c);
}

.o-add-stakeholder {
    height: 15rem;
    text-align: left;
}

.o-add-stakeholder-actual {
    padding: .2rem;
    margin-bottom: 1rem;
}

.o-add-stakeholder-actual.o-max {
    color: var(--button-c);
    background-color: var(--primary-color);
}

.o-add-stakeholder-title {
    padding: .2rem;
    margin-bottom: 1rem;
}

.o-add-stakeholder-item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-bottom: .3rem;
}

.o-add-stakeholder-item-name {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    padding: .2rem;
}

.o-add-stakeholder-item-share {
    -webkit-flex-grow: 0;
            flex-grow: 0;
}

.o-add-stakeholder-item-pc {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    padding-left: .5rem;
}

.o-add-stakeholder-item-action {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    padding-left: .5rem;
}

.o-add-stakeholder-item-action .o-button-icon {
    font-size: .5rem;
    height: 1rem;
    width: 1rem;
}

.o-add-stakeholder-item .o-input-text {
    margin: 0;
}

.o-add-stakeholder-item .o-input-text input {
    padding: .4rem .2rem .2rem .2rem;
    text-align: right;
    width: 4rem;
}

.o-add-stakeholder-share-info {
    padding-top: 1rem;
}

.o-add-stakeholder .o-input-text-completion {
    padding: 0;
    top: 45px;
}

.o-add-stakeholder .o-input-text-completion li {
    padding: .3rem .5rem;
}

.o-add-stakeholder .o-input-text-completion li:hover {
    background-color: var(--button-b);
    color: var(--button-c);
}
.o-dialog-on-air h1 {
    font-size: var(--font-size-l);
}

.o-dialog-on-air h2 {
    font-size: var(--font-size-l);
    text-align: left;
}

.o-dialog-on-air p {
    text-align: left;
}

.o-dialog-on-air .o-input-text {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    text-align: left;
}

.o-dialog-on-air .p-selectbutton {
    text-align: left;
}

.o-dialog-on-air .o-input-text label {
    margin-left: .5rem;
}

.o-dialog-on-air-spinner {
    text-align: center !important;
}

.o-dialog-on-air-warning {
    background-color: var(--warning-bg);
    color: var(--warning-c);
    padding: .3rem;
}

.o-input-file {
    color: #000000;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.o-input-file label {
    margin-bottom: .4rem;
}

.o-input-file .p-button {
    background-color: var(--button-bg);
    color: white;
    border: solid 1px var(--button-b);
}

.o-input-file .p-fileupload-choose:not(.p-disabled):hover {
    background-color: white;
    color: var(--button-bg);
    border: solid 1px var(--button-b);
}

.o-input-file.secondary .p-button {
    background-color: white;
    color: var(--button-bg);
}

.o-input-file.secondary .p-fileupload-choose:not(.p-disabled):hover {
    background-color: var(--button-bg);
    color: white;
}

.o-input-file.disabled .p-button {
    background: var(--button-disabled-bg) !important;
    border: solid 1px var(--button-disabled-b) !important;
    cursor: not-allowed !important;
    color: white !important;
}
.o-input-datetime2-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-bottom: 1rem;
}

.o-input-datetime2-label {
    margin-bottom: .4rem;
}

span.p-calendar {
    width: 100%;
}

.p-calendar .p-datepicker {
    min-width: inherit !important;
}

.o-input-datetime2 .p-inputtext {
    border: 2px solid #132542 !important;
    padding: .8rem 1.5rem !important;
    border-radius: .2rem !important;
    font-size: 1rem !important;
    width: 100%;
}

.o-input-datetime2-value {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.o-input-datetime2-errors {
    display: -webkit-flex;
    display: flex;
    color: red !important;
    padding-top: .5rem !important;
    font-size: 1rem !important;
}

.o-input-datetime2-date .p-inputtext {
    width: 9rem;
}

.o-input-datetime2-time .p-inputtext {
    margin-left: 1rem;
    width: 6rem;
}

.o-input-datetime2 .error {
    color: red;
    font-size: 1rem !important;
    top: 0 !important;
}

.o-post-stakeholder-name {
    padding-left: .5rem;
    text-align: left !important;
}

.o-post-stakeholder-total {
    font-weight: bold;
    text-align: right !important;
}

.o-post-stakeholder-rate {
    font-weight: bold;
}
.o-post-type {
    background-color: var(--button-bg);
    border-color: var(--button-b);
    border-radius: .5rem;
    color: white;
    display: inline-block;
    font-size: 1rem;
    font-weight: bold;
    padding: .8rem 1.5rem;
}

.o-post-modify-views {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.o-post-modify-views .value {
    font-weight: bold;
    margin-right: .3rem;
}

.o-post-modify-page .o-media-upload,
.o-post-modify-page .o-media-refresh {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
}

.o-post-modify-page .o-media-upload .o-input-file {
    margin: 0;
}

.o-post-modify-page .o-dialog-upload-video-name {
    margin-top: 1rem !important;
    font-size: var(--font-size-l);
    font-weight: bold;
}

.o-post-modify-page .o-media-refresh .o-button-icon {
    border-radius: 3px;
    height: 2.05rem;
    width: 5.25rem;
}

.o-post-modify-page .o-steps ul {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.o-post-modify-page .error {
    position: static;
    position: initial;
    top: 0;
    margin-top: 1rem;
}

.o-post-modify-page .o-live-setup {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.o-post-modify-page .o-image-upload-container .live-picture {
    padding: 0 2rem;
}

.o-post-modify-page .o-input-text-container {
    padding: 0 1.5rem;
}

.o-add-stakeholder-item .o-input-text input::after {
    content: "%";
}
.o-recovery {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    padding-top: 4rem;
    width: 100%;
}

.o-recovery-logo {
    width: 60%;
    max-width: 15rem;
    min-width: 10rem;
    text-align: center;
    margin: .5rem 0 2rem 0;
}

.o-recovery-message {
    font-size: 1.5em;
    padding: 0 1rem;
    text-align: center !important;
}

.o-sections-page-footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: .5rem;
}

.o-section-create .error {
    color: red;
    font-size: 1rem !important;
    position: relative;
    top: -1rem !important;
}

.o-section-create .p-dialog {
    height: 24rem;
}

.o-section-create .p-dialog .p-dialog-content {
    height: 100%;
}

.o-section-create-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
}

.o-section-create .o-type {
    font-weight: bold;
    margin: .5rem 0;
    text-align: center;
    text-transform: uppercase;
}

.o-section-create-content .o-type,
.o-section-create-content .o-title {
    font-weight: bold;
}

.o-section-create-content .o-picture {
    margin-bottom: .5rem;
    height: 10rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.o-section-modify .error {
    color: red;
    font-size: 1rem !important;
    position: relative;
    top: -1rem !important;
}

.o-section-modify .p-dialog {
    height: 24rem;
}

.o-section-modify .p-dialog .p-dialog-content {
    height: 100%;
}

.o-section-modify-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
}

.o-section-modify .o-type {
    font-weight: bold;
    margin: .5rem 0;
    text-align: center;
    text-transform: uppercase;
}

.o-section-modify-content .o-title {
    font-weight: bold;
}

.o-section-modify-content .o-picture {
    margin-bottom: .5rem;
    height: 10rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.o-shop-page-footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: .5rem;
}

.o-new-button {
    -webkit-align-items: flex-end;
            align-items: flex-end;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 1rem;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.o-stakeholders-page-footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: .5rem;
}

.o-add-artist {
    height: 15rem;
    text-align: left;
}

.o-add-artist-title {
    padding-bottom: 1rem;
    text-align: center;
}

.o-add-artist-item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-bottom: .3rem;
}

.o-add-artist-item-name {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.o-add-artist-item-share {
    -webkit-flex-grow: 0;
            flex-grow: 0;
}

.o-add-artist-item-pc {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-grow: 0;
            flex-grow: 0;
    padding-left: .5rem;
}

.o-add-artist-item-action {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    padding-left: .5rem;
}

.o-add-artist-item-action .o-button-icon {
    font-size: .5rem;
    height: 1rem;
    width: 1rem;
}

.o-add-artist-item .o-input-text {
    margin: 0;
}

.o-add-artist-item .o-input-text input {
    padding: .4rem .2rem .2rem .2rem;
    text-align: right;
    width: 4rem;
}

.o-add-artist-share-info {
    padding-top: 1rem;
}

.o-add-artist .o-input-text-completion {
    padding: 0;
    top: 45px;
}

.o-add-artist .o-input-text-completion li {
    padding: .3rem .5rem;
}

.o-add-artist .o-input-text-completion li:hover {
    background-color: var(--button-b);
    color: var(--button-c);
}
.o-stakeholder-create-artists {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.o-storecoins-page-footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: .5rem;
}

.o-storecoins-new-button {
    -webkit-align-items: flex-end;
            align-items: flex-end;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 1rem;
}

.o-users-table .fa-check {
    color: #00ff3c;
}

.o-users-table .fa-moon {
    color: #2e3e57;
}

.o-users-table .fa-times {
    color: #ff3c00;
}

.o-users-table .flag {
    height: 1rem;
}

.o-users-filter-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.o-users-filter-container .o-input-select,
.o-users-filter-container .o-input-text,
.o-users-filter-container .o-input-datetime2 {
    margin-right: 1rem;
}

.o-users-table-header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    padding-right: 1rem;
    margin-bottom: 1rem;
}

.o-users-paginator-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.o-users-paginator-info {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    margin-right: 1rem;
}

.o-users-page .p-paginator {
    border: 0;
    text-align: left;
    background-color: transparent !important;
}

.o-users-page .p-paginator .p-highlight {
    background-color: #c0c0c0 !important;
}
