.o-artist-modify .error {
    color: red;
    font-size: 1rem !important;
    position: relative;
    top: -1rem !important;
}

.o-artist-modify-action {
    padding: 0 2rem;
}

.o-artist-modify-picture {
    padding: 0 20%;
}